import { Link } from "react-router-dom";
import "./ProductListing.css";

import Dash from "../../components/Dash/Dash";

import {
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product4_icon,
  product5_icon,
  product6_icon,
  whiteArrow,
  coloredArrow,
  dryCoolProduct,
  dryCool_home_icon,
} from "../../images";
import { useEffect } from "react";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const ProductListing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/products"
        metaTitle="Our Products | Sanathan Textiles"
        metaDesc="We have 3 yarn verticals namely Cotton yarns, Polyester yarns and Yarns for Technical textiles & Industrial end use. Click here to view our entire range and it's USPs."
      />
      <section className="product_listing" data-aos="fade-in">
        <div className="wrapper">
          <div class="breadcrumb_styles">Home {">"} Our Products</div>
          <div className="products">
            <ul>
              <li data-aos="fade-up">
                <h1 className="hollow_title">Our Products</h1>
                <div className="dash_wrapper">
                  <Dash />
                </div>
                <h3>
                  Wide range of 12000+ <br /> Yarn products
                </h3>
                <p>
                  We have 3 yarn verticals namely Cotton Yarns, Polyester Yarns
                  and Yarns for Technical textiles & Industrial end-use. Our
                  wide product offering of more than 12000+ types of Yarn gives
                  the customer a unique opportunity for all his yarn sourcing
                  needs under one roof.
                  <br />
                  <br />
                  Our well designed and integrated manufacturing setup is keenly
                  focused on offering customers made to order (MTO) yarns, niche
                  yarns, coloured yarns and other value-added yarns across all 3
                  main yarn divisions.
                  <br />
                  <br />
                  The success of our relationship lies in the stickiness we
                  create with customers to be their first choice of Yarn
                  supplier.
                </p>
              </li>

              <li data-aos="fade-up">
                <Link to="/products/cotton-yarn">
                  <figure>
                    <img src={product1} alt="sanathan img" />
                  </figure>
                  <div className="products_content">
                    <h3>
                      <img
                        src={product6_icon}
                        className="puro_icon"
                        alt="sanathan img"
                      />
                      Cotton yarns
                    </h3>
                    <p>
                      Our range of Pure Cotton Yarns marketed under our brand ‘
                      <Link to="/products/cotton-yarn" className="new_link">
                        Puro Cotton Yarns
                      </Link>
                      ’ is used to make some of the finest fabrics for apparel,
                      suiting, shirting, bedsheets, home textile and other end
                      uses.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products/cotton-yarn">
                        view product
                        <img
                          className="arrow"
                          src={whiteArrow}
                          alt="sanathan img"
                        />
                      </Link>
                      {/* <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img"/>
                    </span> */}
                    </div>
                  </div>
                </Link>
              </li>

              <li data-aos="fade-up">
                <Link to="/products/polyester-yarn">
                  <figure>
                    <img src={product2} alt="sanathan img" />
                  </figure>
                  <div className="products_content">
                    <h3>Polyester yarns</h3>
                    {/* <p>
                      Our polyester division is a fully integrated setup where
                      our input is petrochemicals to produce different{" "}
                      <Link to="/products/polyester-yarn" className="new_link">
                        types of polyester yarns
                      </Link>
                      .
                    </p> */}
                    <div className="bottom_arrow_container">
                      <Link to="/products/polyester-yarn">
                        view product
                        <img
                          className="arrow"
                          src={whiteArrow}
                          alt="sanathan img"
                        />
                      </Link>
                      {/* <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img"/>
                    </span> */}
                    </div>
                  </div>
                </Link>
              </li>

              <li data-aos="fade-up">
                <Link to="/products/yarn-for-technical-textile">
                  <figure>
                    <img src={product3} alt="sanathan img" />
                  </figure>
                  <div className="products_content">
                    <h3>Yarns for Technical Textile</h3>
                    <p>
                      For our
                      <Link
                        to="/products/yarn-for-technical-textile"
                        className="new_link"
                      >
                        yarns for technical textiles and industrial uses
                      </Link>
                      vertical, we plan to set up an inhouse facility at our
                      Punjab Manufacturing Facility to make specialised high
                      intrinsic viscosity raw material required to make yarns
                      for technical textiles and industrial enduse.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products/yarn-for-technical-textile">
                        view product
                        <img
                          className="arrow"
                          src={whiteArrow}
                          alt="sanathan img"
                        />
                      </Link>
                      {/* <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img"/>
                    </span> */}
                    </div>
                  </div>
                </Link>
              </li>

              <li data-aos="fade-up">
                <Link to="/products/reviro">
                  <figure>
                    <img src={product4} alt="sanathan img" />
                  </figure>
                  <div className="products_content">
                    <h3>
                      <img
                        src={product4_icon}
                        className="revir_icon"
                        alt="sanathan img"
                      />
                    </h3>
                    <span className="subhead">
                      Our range of recycled products
                    </span>
                    <p className="diff_size">
                      We manufacture products from recycled materials which are
                      sold under the brand ‘Sanathan Reviro’.
                    </p>
                    <div className="bottom_arrow_container reviro_button">
                      <Link to="/products/reviro">
                        view product
                        <img
                          className="arrow"
                          src={whiteArrow}
                          alt="sanathan img"
                        />
                      </Link>
                      {/* <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img"/>
                    </span> */}
                    </div>
                  </div>
                </Link>
              </li>

              <li data-aos="fade-up" className="products_inner_wrapper">
                <Link to="/products/born-dyed">
                  <figure>
                    <img src={product5} alt="sanathan img" />
                  </figure>
                  <div className="products_content">
                    <h3>
                      Born{" "}
                      <img
                        src={product5_icon}
                        className="borndyed_icon"
                        alt="sanathan img"
                      />{" "}
                      Dyed™
                    </h3>
                    <span className="subhead">Colour Polyester yarns</span>
                    <p className="diff_size">
                      Our range of{" "}
                      <Link to="/products/born-dyed" className="new_link">
                        polyester coloured yarns
                      </Link>{" "}
                      - ‘BornDyed’ are made using the dope dyed technology where
                      colour pigment is added to the yarns at the polymerisation
                      stage.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products/born-dyed">
                        view product
                        <img
                          className="arrow"
                          src={whiteArrow}
                          alt="sanathan img"
                        />
                      </Link>
                      {/* <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img"/>
                    </span> */}
                    </div>
                  </div>
                </Link>
              </li>

              {/* <li data-aos="fade-up">
                <Link to="/products/functional-yarn">
                  <figure>
                    <img src={product6} alt="sanathan img"/>
                  </figure>
                  <div className="products_content">
                    <h3>Functional Yarns</h3>
                    <p>
                      These Yarn products are developed after continuous
                      research and development. They are designed with special
                      properties to achieve a specific function.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products/functional-yarn">
                        view product
                        <img className="arrow" src={whiteArrow} alt="sanathan img"/>
                      </Link>
                    </div>
                  </div>
                </Link>
              </li> */}

              <li data-aos="fade-up">
                <Link to="products/dry-cool">
                  <figure>
                    <img
                      src={dryCoolProduct}
                      alt="sanathan img"
                      className="drycool_product"
                    />
                  </figure>
                  <div className="products_content">
                    <h3>
                      {/* Dry Cool */}
                      <img
                        src={dryCool_home_icon}
                        className="dry_cool_Product_icon"
                        alt="sanathan img"
                      />{" "}
                    </h3>
                    {/* <p>
                      Fabrics made from our range of DryCool™ yarns are durable,
                      lightweight, and breathable. Used for sportswear,
                      athleisure clothing, innerwear, and many other
                      applications.
                    </p> */}
                    <div className="bottom_arrow_container">
                      <Link to="/products/dry-cool">
                        view product
                        <img
                          className="arrow"
                          src={whiteArrow}
                          alt="sanathan img"
                        />
                      </Link>
                      {/* <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img"/>
                    </span> */}
                    </div>
                  </div>
                </Link>
              </li>

              <li data-aos="fade-up">
                {/* <Link to="/products/customised-yarn"> */}
                <figure>
                  <img src={product7} alt="sanathan img" />
                </figure>
                <div className="products_content">
                  <h3>Customised Yarns</h3>
                  <span className="subhead">Made to Order Yarns</span>
                  <div className="diff_size_p">
                    We offer a varied range of value added products which
                    constitute a significant proportion of our revenues have
                    higher margins since these are tailor-made to customer
                    requirements and distinctive properties and characteristics.{" "}
                    <br />
                    <a className="no_link" href="mailto:sales@sanathan.com">
                      sales@sanathan.com
                    </a>
                  </div>
                  {/* <div className="bottom_arrow_container">
                      <Link to="/products/customised-yarn">
                        view product
                        <img className="arrow" src={whiteArrow} alt="sanathan img"/>
                      </Link>
                    </div> */}
                </div>
                {/* </Link> */}
              </li>
              <li>
                <div className="many_things" data-aos="fade-up">
                  <h3>One Yarn Many Things</h3>
                  <div className="bottom_arrow_container_last">
                    <Link className="knowmore_cta" to="/ouryarn-in-your-life">
                      KNOW MORE
                    </Link>
                    <span className="arrow">
                      <img src={coloredArrow} alt="sanathan img" />
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductListing;
