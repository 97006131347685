import { useEffect } from "react";
import "./Popthird.css";
import "./pop3.scss";
import { pop3 } from "../../images";
import { Link } from "react-router-dom";

const ProductsPopup = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        console.log("close clicked");
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  useEffect(() => {
    window.scrollTo({
      top: 970,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <section className="popup_form_wrapper3">
        <div className="products_popup3">
          <div className="img_and_close_button">
            <figure>
              <img src={pop3} alt="sanathan draw textured yarn" />
            </figure>
            <button type="button" onClick={onRequestClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="wrapper">
            <h3>Draw Textured/Texturized Yarn (DTY)</h3>
            <p>
              Our Draw textured Yarns are produced in India using the latest
              machines from Oerlikon Barmag, Aalidhra Muratec. We offer even
              length yarn with an automated process and to ensure less waste,
              high efficiency, and equal length packages for customers. Our DTY
              yarns find applications in weaving & knitting for fabrics used in
              leisure & activewear, innerwear, school uniforms, mink blankets,
              denim & dress materials, bags, home furnishings and many more. We
              are a global supplier of yarns to automotive fabric manufacturers.
              Over the last 16 years, Sanathan Textiles has established itself
              as an innovative and dependable DTY supplier in India and around
              the world Our Draw textured Yarns are also offered in colours by
              implementing the dope-eyed technology under our brand BornDyed™
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsPopup;
