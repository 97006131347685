import React, { memo } from "react";
import "./Maps.css";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import { IconImg1, IconImg2, IconImg3, IconImg4 } from "../../images";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

// const rounded = num => {
//     if (num > 1000000000) {
//         return Math.round(num / 100000000) / 10 + "Bn";
//     } else if (num > 1000000) {
//         return Math.round(num / 100000) / 10 + "M";
//     } else {
//         return Math.round(num / 100) / 10 + "K";
//     }
// };

const markers = [
  { name: "India", coordinates: [78.962883, 20.593683] },
  { name: "USA", coordinates: [-95.712891, 37.09024] },
  { name: "UK", coordinates: [-13.4306024, 54.2184924] },
  { name: "Spain", coordinates: [-8.2035206, 40.1216758] },
  { offset: "32",offsetX: "10",name: "Argentina", coordinates: [-64.3344304, -31.399084] },
  { offset: "32",offsetX: "10",name: "Chile", coordinates: [-72.5160596, -22.0949319] },
  { name: "Colombia", coordinates: [-83.3895534, 4.5877586] },
  {
    name: "South Africa",
    coordinates: [23.8641549, -31.3811609],
  },
  { name: "Djibouti", coordinates: [42.0668781, 11.8127757] },
  { name: "Egypt", coordinates: [26.3818276, 26.8349378] },
  { name: "Morocco", coordinates: [-11.6441786, 31.7218927] },
  { name: "Tunisia", coordinates: [5.0679709, 33.8004062] },
  { name: "Portugal", coordinates: [-27.8272354, 36.8805697] },
  { name: "Germany", coordinates: [5.9678787, 51.0899048] },
  {
    offset: "10",
    name: "Italy",
    coordinates: [3.7218338, 40.9399763],
    offsetX: "30",
  },
  { name: "France", coordinates: [-2.4365078, 46.1313542] },
  { offset: "-10", name: "Belgium", coordinates: [3.3450835, 50.4956742] },
  { name: "Turkey", coordinates: [30.6868348, 39.0014505] },
  { name: "Nepal", coordinates: [81.8860985, 28.3789963] },
  { name: "Thailand", coordinates: [92.4456997, 12.8824139] },
  { name: "Japan", coordinates: [119.025437, 32.1713311] },
  { name: "South Korea", coordinates: [125.6296572, 35.7982008] },
  { name: "Russia", coordinates: [68.7578162, 49.7345534] },
  { name: "Ukraine", coordinates: [26.691448, 48.2485138] },
  { name: "Sri Lanka", coordinates: [78.4615052, 7.8517305] },
  { name: "Honduras", coordinates: [-88.4538714, 15.2131706] },
  { offset: "-10", name: "Guatemala", coordinates: [-92.4793655, 15.7113811] },
];

const Maps = ({ setTooltipContent }) => {
  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
        {/* <ZoomableGroup> */}
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <>
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  //   onMouseEnter={() => {
                  //     const { NAME } = geo.properties;
                  //     setTooltipContent(`${NAME}`);
                  //   }}
                  //   onMouseLeave={() => {
                  //     setTooltipContent("");
                  //   }}
                  style={{
                    default: {
                      fill: "#E0EDFF",
                      outline: "none",
                    },
                    hover: {
                      fill: "#E0EDFF",
                      outline: "none",
                    },
                  }}
                />
              </>
            ))
          }
        </Geographies>
        {/* </ZoomableGroup> */}
        {markers.map(({ offset, name, coordinates, offsetX }) => (
          <Marker key={name} coordinates={coordinates}>
            {/* <img src={IconImg4} /> */}
            {/* <IconImg1 /> */}
            <g
              fill="none"
              stroke="#1E5093"
              strokeLinecap="round"
              strokeWidth="2"
              //   transform="translate(0, -15)"
              style={{ pointerEvents: "none" }}
            >
              <circle cx="12" cy="10" r="3" />
              <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
            </g>
            <text
              textAnchor="middle"
              x={offsetX}
              y={offset}
              style={{
                fontFamily: "system-ui",
                fill: "#5D5A6D",
                fontSize: "0.7em",
                position: "relative",
                fontWeight: "bold",
              }}
            >
              {name}
            </text>
            {/* <img src="/Group 745.svg" />
                        <text
                            textAnchor="middle"
                            y={markerOffset}
                            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                        > */}
            {/* {name} */}
            {/* </text> */}
          </Marker>
        ))}
      </ComposableMap>
    </>
  );
};

export default memo(Maps);
