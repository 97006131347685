import { useEffect } from "react";
import "./OurSetupInfrastructure.css";
import "./style.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Dash from "../../components/Dash/Dash";
import {
  InfrastructureBannerImage1,
  InfrastructureBannerImage2,
  FleetFactoryImage,
  BenefitImage1,
  BenefitImage2,
  BenefitImage3,
  BenefitImage4,
} from "../../images";
//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";
Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const OurSetupInfrastructure = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);

    var benefits_slider_wrapper = new Swiper(
      ".swiper-container.benefits_slider_wrapper",
      {
        slidesPerView: 1.2,
        spaceBetween: 30,
        breakpoints: {
          767: {
            slidesPerView: 1.5,
            spaceBetween: 50,
          },
          992: {
            slidesPerView: 2.1,
            spaceBetween: 50,
          },
        },
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
      }
    );
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/our-setup/infrastructure"
        metaTitle="Our Setup & Infrastructure | Sanathan Textiles"
        metaDesc="Read more about Sanathan Textiles infrastructure. Explore our integrated manufacturing setup, efficient plant design and centrally located facility."
      />

      <section className="our_setup_infrastructure" data-aos="fade-up">
        <div className="wrapper">
          <div class="breadcrumb_styles">Our Setup {">"} Infrastructure</div>
          <div className="left_section">
            <p className="hollow_title">Our Setup</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h1 className="Infrastructure">Infrastructure</h1>
            <p>
              <h4>
                <strong>Integrated Manufacturing Setup</strong>
              </h4>
              <ul>
                <li>
                  220,000+ MTPA manufacturing capacity consisting of{" "}
                  <Link to="/products/cotton-yarn" className="new_link">
                    Cotton Yarn
                  </Link>
                  ,{" "}
                  <Link to="/products/polyester-yarn" className="new_link">
                    Polyester Yarn
                  </Link>
                  , and Technical Textiles at a single location enabling
                  economies of scale.{" "}
                </li>
                <li>
                  Continuous polymerization Plant (CP) with 100% integrated
                  downstream spinning, texturizing, twisting and air texturizing
                  ensuring high value-added.{" "}
                </li>
                <li>
                  A one-stop shop for a wide variety of products (12,000+)
                  allowing customers the opportunity to source a large variety
                  of yarn products under one roof.
                </li>
              </ul>
            </p>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InfrastructureBannerImage2} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InfrastructureBannerImage1} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>
      <section className="infra_sec2" data-aos="fade-up">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-6">
              <h3>Strategically located</h3>
              <p>
                Our products are manufactured at our facility at Silvassa which
                lies in western Gujarat. The West Gujarat Cluster is amongst the
                major strategic locations for polyester yarn manufacturers in
                India due to availability of manufacturing facilities across
                supply chain of polyester segment. Presence of raw materials
                manufacturers, for MEG and PTA, such as Reliance industries,
                provides a logistical advantage for manufacturers in the
                location. (Source: CRISIL Report).
              </p>
            </div>
            {/* <div className="col-lg-6">
              <h3>Efficient plant design</h3>
              <ul>
                <li>
                  Planned to ensure integration of processes and economy of
                  scale.
                </li>
                <li>
                  Polyester Polymerization plant designed with 3 lustre and
                  single esterification offering product flexibility and variety
                  with low capex and opex.
                </li>
                <li>
                  Equipment designed and supplied by global leaders in the
                  polyester manufacturing industry – Oerlikon Barmag, Reiter,
                  LMW, TMT Japan, Aiki, Alidhra.
                </li>
                <li>
                  Infrastructure designed to handle 1,00,000+ SKUs and to
                  service niche, Made to Order (MTO) products adding to a higher
                  value.
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </section>
      <section className="infra_section2" data-aos="fade-up">
        <div className="wrapper fleet_wrapper">
          <div className="hollow_title" style={{ marginBottom: "200px" }}>
            Our Setup
          </div>
        </div>
        <div className="infra_section2_second_section">
          <div className="wrapper">
            <figure>
              <img src={FleetFactoryImage} alt="sanathan img" />
            </figure>
          </div>
        </div>
        <div className="infra_section2_third_section">
          <div className="benefits_slider_wrapper swiper-container">
            <div className="benefits_slider_heading_section">
              <div className="hollow_title">Benefits</div>
              {width > 767 ? (
                <>
                  <div className="navigations">
                    <button className="left_arrow">Previous</button>
                    <button className="right_arrow">Next</button>
                  </div>
                </>
              ) : null}
            </div>
            <ul className="swiper-wrapper">
              <li className="swiper-slide">
                <figure>
                  <img src={BenefitImage1} alt="sanathan img" />
                </figure>
                <h4>Integrated manufacturing setup</h4>
                <p className="sub_description">
                  The equipment in our facility has been designed and supplied
                  by few of the domestic and globally renowned players in the
                  yarn industry and has been designed to handle high number of
                  SKUs so as to service made to order products as well as high
                  value-added products. <br /> We believe that our automatic
                  doffing, transportation and package handling systems meet
                  global inventory management standards, and our automated
                  warehouse management system ensures high efficiency, less
                  damage and better inventory management in limited space for
                  handling a large number of SKUs. <br /> The process automation
                  and technology help in reduction of labour dependency and
                  brings in more efficiency.
                </p>
              </li>
              <li className="swiper-slide">
                <figure>
                  <img src={BenefitImage2} alt="sanathan img" />
                </figure>
                <h4>Centrally Located Facility</h4>
                <ul className="sub_description">
                  <li>
                    <p>
                      Centrally located in Silvassa, giving access to major
                      textile markets of India.
                    </p>
                  </li>
                  <li>
                    <p>
                      Proximity to the port, affordable power cost, proximity to
                      raw material, and easy availability of labour helps in
                      reducing cost and increase their efficiency.
                    </p>
                  </li>
                  <li>
                    <p>
                      The area is part of Hon. PM Narendra Modi's flagship Smart
                      Cities Mission.
                    </p>
                  </li>
                </ul>
              </li>
              {/* <li className="swiper-slide">
                <figure>
                  <img src={BenefitImage3} alt="sanathan img" />
                </figure>
                <h4>Efficient Designed Plant</h4>
                <ul className="sub_description">
                  <li>
                    <p>
                      Polyester Continuous Polymerization Plan (CP) design with
                      3 lustre & single esterification offering product
                      flexibility with low capex and opex.
                    </p>
                  </li>
                  <li>
                    <p>
                      Equipment designed and supplied by{" "}
                      <Link to="/" className="new_link">
                        global leaders in the yarn industry
                      </Link>
                      - Oerlikon Barmag, Reiter, LMW, TMT, Aiki, Alidhra.
                    </p>
                  </li>
                  <li>
                    <p>
                      Plant designed for handling high SKU to service Made to
                      Order (MTO) products and high value-added products.
                    </p>
                  </li>
                </ul>
              </li> */}
              <li className="swiper-slide">
                <figure>
                  <img src={BenefitImage4} alt="sanathan img" />
                </figure>
                <h4>Automation & Technology Driven</h4>
                <p>
                  The equipment in our facility has been designed and supplied
                  by few of the domestic and globally renowned players in the
                  yarn industry and has been designed to handle high number of
                  SKUs so as to service made to order products as well as high
                  value-added products. <br /> We believe that our automatic
                  doffing, transportation and package handling systems meet
                  global inventory management standards, and our automated
                  warehouse management system ensures high efficiency, less
                  damage and better inventory management in limited space for
                  handling a large number of SKUs. The process automation and
                  technology help in reduction of labour dependency and brings
                  in more efficiency.
                </p>
              </li>
            </ul>
            {width < 767 ? (
              <>
                <div className="navigations">
                  <button className="left_arrow">Previous</button>
                  <button className="right_arrow">Next</button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurSetupInfrastructure;
