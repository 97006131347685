import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./drycool.scss";
import "../style.scss";
import { Link } from "react-router-dom";

import {
  Technicaltext,
  Technicaltext1,
  Technicaltext2,
  Technicaltext3,
  Technicaltext4,
  dryCoolLogo,
  dryCoolVideo,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
import InfoModal from "../../../components/VideoModal/VideoModal";

const DryCool = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [ytVideoLink, setYtVideoLink] = useState("");

  const handleProjectVideoClick = (ytLink) => {
    setYtVideoLink(ytLink);
    setShouldModalOpen(true);
  };

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/products/yarn-for-technical-textile"
        metaTitle="Our Products - Yarns for Technical Textile | Sanathan Textiles"
        metaDesc="Setup in 2017, our division of Yarn for Technical textile & Industrial end use offers high tenacity yarns which are used for a wide variety of applications."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner">
        <div className="wrapper">
          <div class="breadcrumb_styles">Products {">"} Dry Cool</div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <img className="drycool_img" src={dryCoolLogo} alt="sanathan img" />
            {/* <h1 className="yarns">Dry Cool</h1> */}
            {/* <h4 className="capacity">6300 MTPA Manufacturing capacity</h4> */}
            <p>
              The products under this category are made by using dope dyed
              technology wherein the colour pigment is added to the yarn during
              the polymerisation stage. The fabric manufactured from this yarn
              is mainly utilized in the making of outer-wear, shirts, trousers,
              suiting, rainwear, ties, school belts, bags, upholstery etc.
              {/* <Link to="/products/born-dyed" className="new_link">
                Coloured Yarns for Technical Textile
              </Link> */}
            </p>
            {/* <img src={coloredArrow} className="arrow" alt="sanathan img"/> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={Technicaltext4} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="products_inner_watch_now">
        <div className="wrapper">
          <div className="hollow_title">Watch Now</div>
          {/* <div className="video_section">
            <a>
              <img src={youtube_thumb} className="video_image" alt="sanathan img"/>
              <img
                src={playicon}
                className="playicon"alt="sanathan img"
                onClick={() =>
                  handleProjectVideoClick(
                    "https://www.youtube.com/embed/a6y1xzN1WD8"
                  )
                }
              />
            </a>
          </div> */}

          {/* <figure className="for_desk">
            <iframe
              width="100%"
              height="680"
              src="https://www.youtube.com/embed/YT4Dhl_uHd8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
          <figure className="for_mob">
            <iframe
              width="100%"
              height="150"
              src="https://www.youtube.com/embed/YT4Dhl_uHd8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure> */}

          <div className="video_container">
            <video className="video_details" autoPlay muted loop playsInline>
              <source src={dryCoolVideo} type="video/mp4" />
              <source src={dryCoolVideo} type="video/ogg" />
            </video>
          </div>
        </div>
      </section>

      {/* <section className="products_inner_list">
        <div className="wrapper">
          <div className="products_inner_list_heading_wrapper">
            <div className="hollow_title">Manufacturing Unit</div> */}
      {/* <h3>
              Click on any product to view <br /> detailed information
            </h3> */}
      {/* </div>
          <div className="products_inner_listing">
            <div className="product_box">
              <figure>
                <img src={Technicaltext1} alt="sanathan img" />
              </figure> */}
      {/* <button className="tech_btn" onClick={toggleModal}>
                Partially Oriented Yarn
              </button> */}
      {/* </div>
            <div className="product_box">
              <figure>
                <img src={Technicaltext2} alt="sanathan img" />
              </figure> */}
      {/* <button className="tech_btn" onClick={toggleModal}>
                Fully Drawn Yarn
              </button> */}
      {/* </div>
            <div className="product_box">
              <figure>
                <img src={Technicaltext3} alt="sanathan img" />
              </figure> */}
      {/* <button className="tech_btn" onClick={toggleModal}>
                Draw Textured/Texturized Yarn
              </button> */}
      {/* </div>
          </div>
        </div>
      </section> */}

      <section className="technical_sec3">
        <div className="wrapper">
          <h3>Benefits:</h3>
          <div className="row">
            <div className="col-lg-4">
              <ul>
                <li>Moisture-wicking</li>
                <li>Lightweight</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Comfortable</li>
                <li>Breathable</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Durable</li>
                <li>Versatile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <InfoModal
        show={shouldModalOpen}
        onHide={() => setShouldModalOpen(false)}
        ytvideolink={ytVideoLink}
      />
    </>
  );
};

export default DryCool;
