import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./revir.scss";
import "../style.scss";

import {
  coloredArrow,
  Reviro,
  Reviro1,
  Reviro2,
  Reviro3,
  Reviro4,
  Reviro5,
  revicon1,
  revicon2,
  revicon3,
  revicon4,
  revicon5,
  box1,
  box2,
  Revirosvg,
  whiteArrow,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PopCert from "../../../components/Popcertificate/Popcertificate";

const Revir = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [isModalOpencert, setModalIsOpencert] = useState(false);
  const { width } = useWindowDimensions();

  const toggleModalcert = () => {
    setModalIsOpencert(!isModalOpencert);
  };
  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/products/reviro"
        metaTitle="Our Products - Reviro | Sanathan Textiles"
        metaDesc=" We at Sanathan contribute to sustainable textiles by producing yarn from recycled PET chips. With Reviro our endeavour is to contribute Reducing, Reusing and Recycling."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      {isModalOpencert && <PopCert onRequestClose={toggleModalcert} />}
      <section className="products_inner">
        <div className="wrapper">
          <div class="breadcrumb_styles">Products {">"} Reviro</div>
          <div className="left_section">
            <h1 className="hollow_title">Products</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3 className="revir_text">
              Revir
            </h3> */}
            <img className="revior_oimg" src={Revirosvg} alt="sanathan img" />
            <p>
              {/* Setup in 2017, our division of Yarn for Technical textile &
              Industrial end use offers high tenacity yarns which are used for a
              wide variety of applications Equipped with the most advanced
              manufacturing setup designed and delivered by Oerlikon Barmag
              Germany, we offer these yarns from 600 to 6000 denier Technical
              and Industrial use yarns are deemed to be the highest discipline
              in filament manufacturing and can pose a significant challenge for
              the manufacturers. However, our expert and professional team
              tackle these challenges to create the best technical and
              industrial use yarns for our customers. Regular Shrinkage Low
              Shrinkage Super Low Shrinkage BornDyed™ Coloured Yarns for
              Technical Textile . */}
              {/* As the demand for sustainable textile increases, we at Sanathan
              have decided to contribute to the endeavour by producing yarn from
              PET chips which are made from waste recycled bottles. <br />
              With Reviro our endeavour is to contribute to the new worlds
              efforts towards Reducing, Reusing and Recycling */}
              We manufacture products from recycled materials which are sold
              under the brand ‘Sanathan Reviro’...
            </p>
            {/* <img src={coloredArrow} className="arrow" alt="sanathan img"/> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={Reviro} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="products_inner_watch_now reviro_video">
        <div className="wrapper">
          <div className="hollow_title">Watch Now</div>
          <figure className="for_desk">
            <iframe
              width="100%"
              height="680"
              src="https://www.youtube.com/embed/-c-GVI-foRs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
          <figure className="for_mob">
            <iframe
              width="100%"
              height="220"
              src="https://www.youtube.com/embed/-c-GVI-foRs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
        </div>
      </section>

      <section className="reviro_sec2">
        <div className="wrapper">
          <div className="products_inner_listing">
            <div className="product_box">
              <figure className="small_icon">
                <img src={revicon1} alt="sanathan img" />
              </figure>
              <div className="bornimg_desc">
                All Sana Reviro product ranges meet the international recycle
                standard norms and are certified by Global Recycle Standard
                (GRS).
              </div>
              {/* <button onClick={toggleModal}>Partially Oriented Yarn</button> */}
            </div>
            <div className="product_box">
              <figure className="small_icon">
                <img src={revicon2} alt="sanathan img" />
              </figure>
              <div className="bornimg_desc">
                To ensure the best quality and dye guarantee in the yarn, the
                raw material is sourced from a proven GRS certified supplier.
              </div>
              {/* <button onClick={toggleModal}>Fully Drawn Yarn</button> */}
            </div>
            <div className="product_box">
              <figure className="small_icon">
                <img src={revicon3} alt="sanathan img" />
              </figure>
              <div className="bornimg_desc">
                The in-house yarn is produced on the latest Barmag spinning and
                texturing machines.
              </div>
            </div>
            <div className="product_box">
              <figure className="small_icon">
                <img src={revicon4} alt="sanathan img" />
              </figure>
              <div className="bornimg_desc">
                Strict quality checks meet the necessary quality requirements of
                the sportswear and knit industry.
              </div>
            </div>
            <div className="product_box">
              <figure className="small_icon">
                <img src={revicon5} alt="sanathan img" />
              </figure>
              <div className="bornimg_desc">
                Due to consistency in raw material and production hardware, we
                ensure uniformity in quality within the batch and from batch to
                batch.
              </div>
            </div>
          </div>
        </div>
      </section>

      {width > 767 ? (
        <section
          className="reviro_sec3"
          // data-aos="fade-up"
        >
          <div className="availablein wrapper">
            <div className="hollow_title">Available In As</div>
            <div className="box_list">
              <ul className="two_box">
                <li>
                  <figure>
                    <img src={box1} alt="sanathan img" />
                  </figure>
                  <div className="box_content">
                    <div className="box_head">Drawn Textured Yarn</div>
                    <p>
                      Lustre: Semi Dull. Denier range : 75-300 denier Filament
                      range : 36 - 108 filaments RW and Dope Dyed
                    </p>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src={box2} alt="sanathan img" />
                  </figure>
                  <div className="box_content">
                    <div className="box_head">Drawn Textured Yarn</div>
                    <p>
                      Lustre: Semi Dull. Denier range : 75-300 denier Filament
                      range : 36 - 108 filaments RW and Dope Dyed
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      ) : (
        <section className="reviro_sec3 wrapper">
          <div className="hollow_title">Available In As</div>
          <div className="box_list">
            <div className="two_box">
              <div className="box_content">
                <h3 className="box_head">Drawn Textured Yarn</h3>
                <p>
                  Lustre: Semi Dull. Denier range : 75-300 denier Filament range
                  : 36 - 108 filaments RW and Dope Dyed
                </p>
              </div>
              <img src={box1} alt="sanathan img" className="box_img" />
            </div>
            <div className="two_box">
              <div className="box_content">
                <h3 className="box_head">Drawn Textured Yarn</h3>
                <p>
                  Lustre: Semi Dull. Denier range : 75-300 denier Filament range
                  : 36 - 108 filaments RW and Dope Dyed
                </p>
              </div>
              <img src={box2} alt="sanathan img" className="box_img" />
            </div>
          </div>
        </section>
      )}

      <section className="products_inner_list_revir">
        <div className="wrapper">
          <div className="products_inner_list_heading_wrapper">
            {/* <div className="hollow_title">Product list</div> */}
            <h3>
              Our Process revolves around using recyclable waste PET bottles to
              produce yarn that can be woven into polyester clothing.
            </h3>
          </div>
          <div className="products_inner_listing">
            {/* <div className="product_box">
              <figure>
                <img src={Reviro1} alt="sanathan img"/>
                <p className="img_desc">
                  Recyclable waste PET bottles are sorted from other plastics
                  such as PVC and HDPE
                </p>
              </figure>
              <button className="reviro_btn" onClick={toggleModal}>
              Partially Oriented Yarn
              </button>
            </div> */}
            <div className="product_box">
              <figure>
                <img src={Reviro2} alt="sanathan img" />
                <p className="img_desc">
                  Waste PET bottles are thoroughly cleaned, sorted, de-capped
                  and processed into flakes
                </p>
              </figure>
              {/* <button className="reviro_btn" onClick={toggleModal}> */}
              {/* Fully Drawn Yarn */}
              {/* </button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={Reviro5} alt="sanathan img" />
                <p className="img_desc">
                  Polyester chips are turned into high-grade flakes and are
                  further made into filament polyester yarn
                </p>
              </figure>
              {/* <button className="reviro_btn" onClick={toggleModal}> */}
              {/* Twisted Yarns */}
              {/* </button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={Reviro3} alt="sanathan img" />
                <p className="img_desc">
                  The resulting yarn is ready to be made into polyester clothing
                </p>
              </figure>
              {/* <button className="reviro_btn" onClick={toggleModal}> */}
              {/* Draw Textured/Texturized Yarn */}
              {/* </button> */}
            </div>
            {/* <div className="product_box">
              <figure>
                <img src={Reviro4} alt="sanathan img"/>
                <p className="img_desc">
                  These chips are further made into filament polyester yarn
                </p>
              </figure>
              <button className="reviro_btn" onClick={toggleModal}>
              Air Textured Yarn
              </button>
            </div> */}
          </div>
        </div>
      </section>
      <section className="revior_sec4">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-7 first_section">
              <div className="big_text">
                All Sanathan Reviro products are certified by Global Recycle
                Standard (GRS).
              </div>
              <div className="btn_wrapper">
                <a className="sanathan_btn" onClick={toggleModalcert}>
                  <span className="download_btn_text">VIEW CERTIFICATE</span>
                  <img
                    src={whiteArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-5 second_section">
              <div className="row">
                <div className="col-6 col-lg-6">
                  <div className="big_text1">RE</div>
                </div>
                <div className="col-6 col-lg-6">
                  <div className="big_text2">
                    {/* DUCE
                    <br />
                    USE
                    <br />
                    CYCLE */}
                    <ul>
                      <li className="inner_list">DUCE</li>
                      <li className="inner_list">USE</li>
                      <li className="inner_list">CYCLE</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="revior_sec5">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-4 first_section">
              <div className="big_text">
                Recycling one plastic bottle saves energy to power a light bulb
                for an hour.
              </div>
            </div>
            <div className="col-lg-4 second_section">
              <div className="big_text">
                We can reduce PET bottles in oceans to make clothes, shoes, bags
                instead of just throwing them away.
              </div>
            </div>
            <div className="col-lg-4 third_section">
              <div style={{ border: "none" }} className="big_text">
                By recycling waste PET bottles to make Sanathan Reviro Yarns ,
                We use 90% less water, 66% less energy and emit 26% fewer
                greenhouse gases.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Revir;
