import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import "./Header.scss";
import { ColorLogo, WhiteLogo } from "../../images";
import Careers from "../Careers/Careers";

const Header = () => {
  const [colorChange, setColorchange] = useState(false);
  const [notHomePage, setNotHomePage] = useState(false);
  const [notYarnPage, setNotYarnPage] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  var width = window.innerWidth;
  const [isModalCareersOpen, setCareersModalIsOpen] = useState(false);

  useEffect(() => {
    console.log(window.location.pathname);
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/ouryarn-in-your-life"
    ) {
      window.addEventListener("scroll", changeNavbarColor);
    } else {
      setNotHomePage(true);
    }
    return () => {
      window.removeEventListener("scroll", () => changeNavbarColor);
    };
  }, [window.location.pathname]);

  const changeNavbarColor = () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/ouryarn-in-your-life"
    ) {
      if (window.scrollY >= 80) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    }
  };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const toggleCareersModal = () => {
    setCareersModalIsOpen(!isModalCareersOpen);
  };

  return (
    <>
      {isModalCareersOpen && <Careers onRequestClose={toggleCareersModal} />}
      {width > 1023 ? (
        notHomePage ? (
          <header className="header">
            <div className="wrapper">
              <div className="header_link">
                <Link to="/">
                  <img
                    className="img_width"
                    src={ColorLogo}
                    alt="Sanathan Textiles Logo"
                  />
                </Link>
              </div>
              <nav>
                <ul className={colorChange ? "top_nav" : "top_nav"}></ul>
                <ul className={colorChange ? "bottom_nav" : "bottom_nav"}>
                  <li>
                    <div className="dropdown">
                      <button>
                        about us
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/about-us/the-company"
                          activeClassName="active"
                        >
                          our company
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-team"
                          activeClassName="active"
                        >
                          Our Team
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-journey"
                          activeClassName="active"
                        >
                          our journey
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/products"
                      activeClassName="active"
                      onClick={handleClick}
                    >
                      products
                    </NavLink>
                  </li>
                  <li>
                    <div className="dropdown">
                      <button>
                        our setup
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/our-setup/infrastructure"
                          activeClassName="active"
                        >
                          infrastructure
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/tech-in-textile"
                          activeClassName="active"
                        >
                          Tech In Textile
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/certifications"
                          activeClassName="active"
                        >
                          certifications
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/quality-and-r+d"
                          activeClassName="active"
                        >
                          quality and r+d
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/our-sales" activeClassName="active">
                      our sales
                    </NavLink>
                  </li>
                  <li>
                    {/* <div className="dropdown"> */}
                    {/* <button> */}
                    <NavLink exact to="/investor-relations">
                      investors
                      {/* <i className="fa fa-caret-down"></i> */}
                    </NavLink>
                    {/* </button> */}

                    {/* <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/investors/company-governance-policies"
                          activeClassName="active"
                        >
                          Company Governance Policies
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/financial-results"
                          activeClassName="active"
                        >
                          Financial Results
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/list-of-material-creditors"
                          activeClassName="active"
                        >
                          List of Material Creditors
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/industry-report"
                          activeClassName="active"
                        >
                          Industry Report
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/drhp"
                          activeClassName="active"
                        >
                          DRHP
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/shareholding-pattern"
                          activeClassName="active"
                        >
                          Shareholding Pattern
                        </NavLink>
                      </div> */}
                    {/* </div> */}
                  </li>
                  <li>
                    <a href="#" onClick={toggleCareersModal}>
                      Careers
                    </a>
                  </li>
                  <li>
                    <NavLink exact to="/esg" activeClassName="active">
                      esg
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/newsroom" activeClassName="active">
                      newsroom
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/contact-us"
                      activeClassName="active"
                      onClick={handleClick}
                    >
                      contact us
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
        ) : (
          <header className={colorChange ? "changeColor" : "homepage"}>
            <div className="wrapper">
              <div className="header_link">
                <Link to="/">
                  <img
                    src={colorChange ? ColorLogo : WhiteLogo}
                    alt="Sanathan Textiles Logo"
                  />
                </Link>
              </div>
              <nav>
                <ul className={colorChange ? "top_nav" : "top_nav"}></ul>
                <ul className={colorChange ? "bottom_nav" : "bottom_nav"}>
                  <li>
                    <div className="dropdown">
                      <button>
                        about us
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/about-us/the-company"
                          activeClassName="active"
                        >
                          Our Company
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-team"
                          activeClassName="active"
                        >
                          Our Team
                        </NavLink>
                        <NavLink
                          exact
                          to="/about-us/our-journey"
                          activeClassName="active"
                        >
                          our journey
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/products"
                      activeClassName="active"
                      onClick={handleClick}
                    >
                      products
                    </NavLink>
                  </li>
                  <li>
                    <div className="dropdown">
                      <button>
                        our setup
                        <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/our-setup/infrastructure"
                          activeClassName="active"
                        >
                          infrastructure
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/tech-in-textile"
                          activeClassName="active"
                        >
                          Tech In Textile
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/certifications"
                          activeClassName="active"
                        >
                          certifications
                        </NavLink>
                        <NavLink
                          exact
                          to="/our-setup/quality-and-r+d"
                          activeClassName="active"
                        >
                          quality and r+d
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink exact to="/our-sales" activeClassName="active">
                      our sales
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/our-sales" activeClassName="active">
                      our sales
                    </NavLink>
                  </li>
                  <li>
                    {/* <div className="dropdown"> */}
                    <NavLink
                      exact
                      to={"/investor-relations"}
                      activeClassName="active"
                    >
                      investors
                    </NavLink>
                    {/* <div className="dropdown_content">
                        <NavLink
                          exact
                          to="/investors/company-governance-policies"
                          activeClassName="active"
                        >
                          Company Governance Policies
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/financial-results"
                          activeClassName="active"
                        >
                          Financial Results
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/list-of-material-creditors"
                          activeClassName="active"
                        >
                          List of Material Creditors
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/industry-report"
                          activeClassName="active"
                        >
                          Industry Report
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/drhp"
                          activeClassName="active"
                        >
                          DRHP
                        </NavLink>
                        <NavLink
                          exact
                          to="/investors/shareholding-pattern"
                          activeClassName="active"
                        >
                          Shareholding Pattern
                        </NavLink>
                      </div> */}
                    {/* </div> */}
                  </li>
                  <li>
                    <a href="#" onClick={toggleCareersModal}>
                      Careers
                    </a>
                  </li>
                  <li>
                    <NavLink exact to="/esg" activeClassName="active">
                      esg
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/newsroom" activeClassName="active">
                      newsroom
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/contact-us"
                      activeClassName="active"
                      onClick={handleClick}
                    >
                      contact us
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
        )
      ) : (
        <header className="mobile_header">
          <div className="wrapper">
            <div className="header_link">
              <Link to="/">
                <img className="img_width" src={ColorLogo} alt="Logo" />
              </Link>
            </div>
            <div
              className={`toggle_btn ${openMenu ? "open" : ""}`}
              onClick={handleClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <nav className={`mobile_nav ${openMenu ? "open" : ""}`}>
            <ul>
              <li>
                <div className="dropdown">
                  <button>
                    about us
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown_content">
                    <NavLink
                      exact
                      to="/about-us/the-company"
                      activeClassName="active"
                    >
                      Our Company
                    </NavLink>
                    <NavLink
                      exact
                      to="/about-us/our-team"
                      activeClassName="active"
                    >
                      Our Team
                    </NavLink>
                    <NavLink
                      exact
                      to="/about-us/our-journey"
                      activeClassName="active"
                    >
                      our journey
                    </NavLink>
                  </div>
                </div>
              </li>
              <li>
                <NavLink
                  exact
                  to="/products"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  products
                </NavLink>
              </li>
              <li>
                <div className="dropdown">
                  <button>
                    our setup
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown_content">
                    <NavLink
                      exact
                      to="/our-setup/infrastructure"
                      activeClassName="active"
                    >
                      infrastructure
                    </NavLink>
                    <NavLink
                      exact
                      to="/our-setup/tech-in-textile"
                      activeClassName="active"
                    >
                      Tech In Textile
                    </NavLink>
                    <NavLink
                      exact
                      to="/our-setup/certifications"
                      activeClassName="active"
                    >
                      certifications
                    </NavLink>
                    <NavLink
                      exact
                      to="/our-setup/quality-and-r+d"
                      activeClassName="active"
                    >
                      quality and r+d
                    </NavLink>
                  </div>
                </div>
              </li>
              <li>
                <NavLink exact to="/our-sales" activeClassName="active">
                  our sales
                </NavLink>
              </li>
              <li>
                {/* <div className="dropdown"> */}
                investors
                {/* <i className="fa fa-caret-down"></i>
                  <div className="dropdown_content">
                    <NavLink
                      exact
                      to="/investors/company-governance-policies"
                      activeClassName="active"
                    >
                      Company Governance Policies
                    </NavLink>
                    <NavLink
                      exact
                      to="/investors/financial-results"
                      activeClassName="active"
                    >
                      Financial Results
                    </NavLink>
                    <NavLink
                      exact
                      to="/investors/list-of-material-creditors"
                      activeClassName="active"
                    >
                      List of Material Creditors
                    </NavLink>
                    <NavLink
                      exact
                      to="/investors/industry-report"
                      activeClassName="active"
                    >
                      Industry Report
                    </NavLink>
                    <NavLink
                      exact
                      to="/investors/drhp"
                      activeClassName="active"
                    >
                      DRHP
                    </NavLink>
                    <NavLink
                      exact
                      to="/investors/shareholding-pattern"
                      activeClassName="active"
                    >
                      Shareholding Pattern
                    </NavLink>
                  </div> */}
                {/* </div> */}
              </li>
              <li>
                <a href="#" onClick={toggleCareersModal}>
                  Careers
                </a>
              </li>
              <li>
                <NavLink exact to="/esg" activeClassName="active">
                  esg
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/newsroom" activeClassName="active">
                  newsroom
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/contact-us"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  contact us
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>
      )}
    </>
  );
};

export default Header;
