import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import InfoModal from "../../components/VideoModal/VideoModal";

import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";

import "./Home.css";
import "./Home.scss";

import {
  highlights_2,
  playicon,
  number_image,
  video_image,
  highlights_1,
  product6_icon,
  product5_icon,
  product4_icon,
  esg,
  our_yarn_1,
  our_yarn_2,
  our_yarn_3,
  our_yarn_4,
  our_yarn_5,
  our_yarn_6,
  our_yarn_7,
  coloredArrow,
  whiteArrow,
  BrandImage1,
  BrandImage2,
  BrandImage3,
  BrandImage4,
  BrandImage5,
  BrandImage6,
  BrandImage7,
  BrandImage8,
  BrandImage9,
  BrandImage10,
  BrandImage11,
  BrandImage12,
  BrandImage13,
  BrandImage14,
  BrandImage15,
  BrandImage16,
  BrandImage17,
  BrandImage18,
  BrandImage19,
  BrandImage20,
  BrandImage21,
  BrandImage22,
  BrandImage23,
  BrandImage24,
  BrandImage25,
  BrandImage26,
  BrandImage27,
  BrandImage28,
  BrandImage29,
  BrandImage30,
  homepagenews,
  dryCool_home_icon,
} from "../../images";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const Home = () => {
  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [ytVideoLink, setYtVideoLink] = useState("");

  const handleProjectVideoClick = (ytLink) => {
    setYtVideoLink(ytLink);
    setShouldModalOpen(true);
  };

  const [isOpen, setOpen] = useState(false);

  const toggleState = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    var clients_slider_wrapper = new Swiper(
      ".swiper-container.clients_slider_wrapper",
      {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        freeMode: true,
        grabCursor: false,
        allowTouchMove: false,
        slidesPerView: 7,
        autoplay: {
          delay: 1,
          disableOnInteraction: true,
        },
        speed: 2500,
        breakpoints: {
          300: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 5.5,
          },
        },
      }
    );

    var home_sec2_fifth_row_swiper_container = new Swiper(
      ".swiper-container.home_sec2_fifth_row_swiper_container",
      {
        loop: true,
        loopedSlides: 7,
        allowTouchMove: true,
        autoplay: {
          delay: 2000,
        },
        breakpoints: {
          1023: {
            slidesPerView: 3.7,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        },
      }
    );

    var home_sec2_fifth_row_mobile_swiper_container = new Swiper(
      ".swiper-container.home_sec2_fifth_row_mobile_swiper_container",
      {
        autoplay: {
          delay: 2000,
        },
        loop: true,
        loopedSlides: 7,
        allowTouchMove: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
            autoplay: {
              delay: 3000,
            },
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
            autoplay: {
              delay: 3000,
            },
          },
        },
      }
    );

    var banner_second_slider_container = new Swiper(
      ".swiper-container.banner_second_slider_container",
      {
        slidesPerView: 1,
        loop: true,
        autoplay: true,
        loopedSlides: 3,
        pagination: {
          el: ".second_slider .swiper-pagination2",
          clickable: true,
        },
        paginationClickable: true,
      }
    );

    var text_slider_wrapper = new Swiper(
      ".swiper-container.text_slider_wrapper",
      {
        slidesPerView: 1,
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 2000,
        },
        direction: "vertical",
      }
    );
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/"
        metaTitle="Sanathan Textiles Polyester Yarn Manufacturer in India & A Global Supplier of Technical Textiles"
        metaDesc="Sanathan Textiles, a global supplier of Technical Textiles & Polyester Yarn manufacturer in India  with 3 yarn business verticals to offer a wide variety of industries"
      />
      <main>
        <section className="banner" data-aos="fade-in">
          <div className="wrapper">
            <div className="wrapper_container">
              <div className="left_section" data-aos="fade-up">
                <h1 className="h2">
                  Our{" "}
                  <span className="style_heading_of_banner_content">Yarns</span>
                </h1>
                <h2>Are used for</h2>
                <h2 className="style_heading_of_banner_content">
                  <div className="text_slider_wrapper swiper-container">
                    <ul className="swiper-wrapper">
                      <li className="swiper-slide">
                        <div>Seatbelts</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Luggage</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Marine Ropes</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Medical Masks</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Geogrid Fabric</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Apparels</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Home Textiles</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Sportswear</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Innerwear</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Fishing Nets</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Banner Fabric</div>
                      </li>
                      <li className="swiper-slide">
                        <div>Footwear</div>
                      </li>
                    </ul>
                  </div>
                </h2>
                <h2 className="custom_size">one yarn many things</h2>
              </div>
            </div>
          </div>
        </section>
        <div
          className="banner_right_section banner_slider_desktop_view"
          data-aos="fade-up"
        >
          <div className="main_slider_wrapper">
            <div className="second_slider">
              <div className="swiper-container banner_second_slider_container">
                <div className="second_slider_wrapper swiper-wrapper">
                  {/* <div className="second_slider_wrapper_content swiper-slide">
                    <span className="second_slider_wrapper_content_heading">
                      <img
                        src={product6_icon}
                        className="puro_icon"
                        alt="sanathan img"
                      />
                      Cotton Yarns
                    </span>
                    <p className="second_slider_wrapper_content_description">
                      Our range of pure Cotton Yarn products marketed under our
                      brand ‘PURO’ are used to make some of the finest fabrics
                      for apparels, suiting, shirting, bedsheets, home textile
                      and other end uses.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products">VIEW PRODUCT</Link>
                      <span className="arrow">
                        <img src={whiteArrow} alt="sanathan img" />
                      </span>
                    </div>
                  </div> */}
                  <div className="second_slider_wrapper_content1 swiper-slide">
                    <span className="second_slider_wrapper_content_heading">
                      Polyester yarns
                    </span>
                    <p className="second_slider_wrapper_content_description">
                      We offer a extensive range of Yarn products across many
                      yarn categories in the polyester portfolio. Our integrated
                      well designed continuous polymerization setup with 3
                      lustres give us the flexibility to meet the customers
                      every Yarn product need.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products">VIEW PRODUCT</Link>
                      <span className="arrow">
                        <img src={whiteArrow} alt="sanathan img" />
                      </span>
                    </div>
                  </div>
                  <div className="second_slider_wrapper_content2 swiper-slide">
                    <span className="second_slider_wrapper_content_heading">
                      Yarns for Technical Textile
                    </span>
                    <p className="second_slider_wrapper_content_description">
                      Setup in 2017, our division of Yarn for Technical textile
                      & Industrial end use offers high tenacity yarns which are
                      used for a wide variety of applications.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products">VIEW PRODUCT</Link>
                      <span className="arrow">
                        <img src={whiteArrow} alt="sanathan img" />
                      </span>
                    </div>
                  </div>
                  <div className="second_slider_wrapper_content3 swiper-slide">
                    <span className="second_slider_wrapper_content_heading">
                      <img
                        src={product4_icon}
                        className="revir_icon"
                        alt="sanathan img"
                      />
                    </span>
                    <p className="second_slider_wrapper_content_description">
                      The fashion and textile industry continues to leave behind
                      a huge environmental footprint. With growing environmental
                      concerns and sustainability awareness, recycling waste
                      into useful products is the need of the hour.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products">VIEW PRODUCT</Link>
                      <span className="arrow">
                        <img src={whiteArrow} alt="sanathan img" />
                      </span>
                    </div>
                  </div>
                  <div className="second_slider_wrapper_content4 swiper-slide">
                    <span className="second_slider_wrapper_content_heading">
                      Born{" "}
                      <img
                        src={product5_icon}
                        className="borndyed_icon"
                        alt="sanathan img"
                      />{" "}
                      Dyed
                    </span>
                    <p className="second_slider_wrapper_content_description">
                      Our range of coloured polyester yarns - ‘BornDyed’ are
                      made using the dope dyed technology where colour pigment
                      is added to the yarns at the polymerisation stage.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products">VIEW PRODUCT</Link>
                      <span className="arrow">
                        <img src={whiteArrow} alt="sanathan img" />
                      </span>
                    </div>
                  </div>
                  <div className="second_slider_wrapper_content5 swiper-slide">
                    <span className="second_slider_wrapper_content_heading">
                      {/* Dry Cool */}
                      <img
                        src={dryCool_home_icon}
                        className="dry_cool_icon"
                        alt="sanathan img"
                      />{" "}
                    </span>
                    <p className="second_slider_wrapper_content_description">
                      Fabrics made from our range of DryCool™ yarns are durable,
                      lightweight, and breathable. Used for sportswear,
                      athleisure clothing, innerwear, and many other
                      applications.
                    </p>
                    <div className="bottom_arrow_container">
                      <Link to="/products">VIEW PRODUCT</Link>
                      <span className="arrow">
                        <img src={whiteArrow} alt="sanathan img" />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination2"></div>
              </div>
            </div>
          </div>
        </div>
        <section className="home_sec2 home_sec2_desktop">
          <div className="wrapper">
            <div
              className="home_sec2_first_row home_sec2_first_row_desktop"
              data-aos="fade-up"
            >
              <Link to="/ouryarn-in-your-life">
                <h3>
                  We manufacture Yarns which are
                  <br />
                  used as many products in our daily
                  <br />
                  lives, Everyday, Everywhere!
                </h3>
              </Link>
              <div className="home_sec2_first_row_desc">
                {/* <Link to="/products">
                  <h3 style={{ fontFamily: "Gordita-Bold" }}>30% +</h3>

                  <p>
                    revenue from speciality yarns, niche yarns, customised (MTO)
                    yarns and functional yarns
                  </p>
                </Link> */}
                <div className="bottom_arrow_container">
                  <Link to="/esg">
                    <span>Go sustainable</span>
                    <i class="las la-arrow-right"></i>
                  </Link>
                  {/* <span className="arrow">
                    <img src={whiteArrow} alt="sanathan img" />
                  </span> */}
                </div>
              </div>
            </div>
            <div
              className="home_sec2_first_row home_sec2_first_row_mobile"
              data-aos="fade-up"
            >
              <h3>
                We manufacture yarn products which are used in our daily lives,
                everyday, everywhere!
              </h3>
              <div className="home_sec2_first_row_desc">
                <p>
                  <strong>52%</strong> contribution of Sale from high value
                  Products, Niche Products {"&"} Customised products.
                </p>
                <div className="bottom_arrow_container">
                  <Link to="/esg">Go sustainable</Link>
                  <span className="arrow">
                    <img src={coloredArrow} alt="sanathan img" />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="home_sec2_second_row home_sec2_second_row_desktop"
              data-aos="fade-up"
            >
              <ul className="home_sec2_second_row_content">
                <li>
                  <Link to="/our-sales">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>25+</h3>

                    <h5>
                      Countries export <br />
                      Global sales network
                    </h5>
                  </Link>
                </li>
                <li>
                  <Link to="/products">
                    <img src={number_image} alt="sanathan img" />

                    <h5>
                      Yarn businesses <br /> -Cotton Yarns <br />
                      -Polyester Yarns <br />
                      -Yarns for Technical textiles
                    </h5>
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/our-journey">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>100+</h3>

                    <h5>
                      years of cumulative promoter experience in Yarn industry
                    </h5>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="home_sec2_second_row third_row home_sec2_second_row_desktop"
              data-aos="fade-up"
            >
              <ul className="home_sec2_second_row_content">
                {/* <li>
                  <Link to="/esg">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>17%</h3>

                    <h5>
                      energy used from renewable <br />
                      sources
                    </h5>
                  </Link>
                </li> */}
                <li>
                  <Link to="/about-us/our-team">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>3500+</h3>

                    <h5>Manpower employed</h5>
                  </Link>
                </li>
                <li>
                  <Link to="/products">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>12000+</h3>

                    <h5>Yarn Products</h5>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="home_sec2_second_row home_sec2_second_row_mobile"
              data-aos="fade-up"
            >
              <ul className="home_sec2_second_row_content">
                <li>
                  <h3>60+</h3>
                  <h5>Countries export sales</h5>
                </li>
                <li>
                  <img src={number_image} alt="sanathan img" />
                  <h5>Yarn Divisions</h5>
                </li>
                <li>
                  <h3>3500+</h3>
                  <h5>Manpower employed</h5>
                </li>
              </ul>
            </div>
            <div className="home_sec2_fifth_row" data-aos="fade-up">
              <div className="home_sec2_fifth_row_first_row">
                <div className="home_sec2_fifth_row_heading">
                  <div className="hollow_title home_sec2_fifth_row_heading_desktop">
                    One Yarn Many Things
                  </div>
                </div>
                <div className="home_sec2_fifth_row_swiper_wrapper">
                  <div className="swiper-container home_sec2_fifth_row_swiper_container">
                    <ul className="swiper-wrapper animate_wrapper">
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_1} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Medical</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Mask</p>
                              </li>
                              <li>
                                <p>- Bandages</p>
                              </li>
                              <li>
                                <p>- PPE Kits</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_2} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Sports {"&"} Athleisure</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Footwear</p>
                              </li>
                              <li>
                                <p>- Track suit</p>
                              </li>
                              <li>
                                <p>- Jeresey</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_3} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Apparel</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Shirting {"&"} Suiting</p>
                              </li>
                              <li>
                                <p>- Denim Fabrics</p>
                              </li>
                              <li>
                                <p>- Saree {"&"} Dress Material</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_4} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Active {"&"} Inner Wear</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Lingerie</p>
                              </li>
                              <li>
                                <p>- Swim wear</p>
                              </li>
                              <li>
                                <p>- Yoga {"&"} Gym</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_5} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Automotive</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Seat Belts</p>
                              </li>
                              <li>
                                <p>- Seat Cover</p>
                              </li>
                              <li>
                                <p>- Upholstery Fabrics</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_6} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Home Textiles</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Carpets {"&"} Bathmats</p>
                              </li>
                              <li>
                                <p>- Curtains {"&"} Cushions</p>
                              </li>
                              <li>
                                <p>- Bedsheets {"&"} Towels</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide animate">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_7} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_slider_content">
                            <h5>Technical Textiles</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Geogrid Fabrics</p>
                              </li>
                              <li>
                                <p>- Safety Sling</p>
                              </li>
                              <li>
                                <p>- Ropes {"&"} Nets</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="home_sec2_third_row" data-aos="fade-up">
              <ul className="home_sec2_third_row_img_container">
                <li>
                  <a href="/investor-relations">
                    <figure>
                      <img src={highlights_1} alt="sanathan img" />
                    </figure>
                    <figcaption>Investor Relations</figcaption>
                  </a>
                </li>
                <li>
                  <NavLink to="/newsroom">
                    <figure>
                      <img src={homepagenews} alt="sanathan img" />
                    </figure>
                    <figcaption>Newsroom</figcaption>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="home_sec2_fourth_row home_sec2_fourth_row_desktop">
              <div className="wrapper">
                <div className="home_sec2_fourth_row_left">
                  <h4>Tech in Textile</h4>
                  <p>
                    We have an inhouse testing facility with equipment, such as
                    wrap reel machine and weigh balance for denier testing (i.e.
                    to determine the fibre thickness of individual threads or
                    filaments), yarn strength tester to determine tensile
                    properties (i.e., to check elongation and tenacity of yarns)
                    and color viewing booth for visual colour evaluation. We
                    have harnessed the benefits of technology and digitization
                    to bring about cohesion in planning, production and
                    processing of our products...
                  </p>
                  <div className="bottom_arrow_container">
                    <Link to="/our-setup/tech-in-textile">Explore</Link>
                    <span className="arrow">
                      <img src={whiteArrow} alt="sanathan img" />
                    </span>
                  </div>
                </div>
                <div className="home_sec2_fourth_row_right">
                  <a>
                    <img
                      src={video_image}
                      className="video_image"
                      alt="sanathan img"
                    />
                    <img
                      src={playicon}
                      alt="sanathan img"
                      className="playicon"
                      onClick={() =>
                        handleProjectVideoClick(
                          "https://www.youtube.com/embed/a6y1xzN1WD8"
                        )
                      }
                    />
                  </a>
                </div>
                <div className="home_sec2_fourth_row_right_link">
                  <div className="bottom_arrow_container">
                    <Link to="/our-setup/infrastructure">
                      Visit our Factory
                    </Link>
                    <span className="arrow">
                      <img src={coloredArrow} alt="sanathan img" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_sec2 home_sec2_mobile_1">
          <div className="wrapper">
            <div className="home_sec2_first_row home_sec2_first_row_desktop">
              <h3>
                We manufacture yarn products
                <br /> which are used in our daily lives,
                <br /> everyday, everywhere!
              </h3>
              <div className="home_sec2_first_row_desc">
                <p>
                  <strong>52%</strong> contribution of Sale from high value
                  Products, Niche Products {"&"} Customised products.
                </p>
                <div className="bottom_arrow_container">
                  <Link to="/esg">Go sustainable</Link>
                  <span className="arrow">
                    <img src={coloredArrow} alt="sanathan img" />
                  </span>
                </div>
              </div>
            </div>
            <div className="home_sec2_first_row home_sec2_first_row_mobile">
              <Link to="/ouryarn-in-your-life">
                <h3>
                  We manufacture Yarns which are used as many products in our
                  daily lives, Everyday, Everywhere!
                </h3>
              </Link>
              <div className="home_sec2_first_row_desc cust_h3">
                <Link to="/products">
                  <h3>52%</h3>{" "}
                  <p>
                    revenue from speciality yarns, niche yarns, customised (MTO)
                    yarns and functional yarns
                  </p>
                </Link>
                <div className="bottom_arrow_container">
                  <Link to="/esg">Go sustainable</Link>
                  <span className="arrow">
                    <img src={coloredArrow} alt="sanathan img" />
                  </span>
                </div>
              </div>
            </div>
            <div className="home_sec2_second_row home_sec2_second_row_mobile">
              <ul className="home_sec2_second_row_content">
                <li>
                  <Link to="/products">
                    <img src={number_image} alt="sanathan img" />
                    <h5 style={{ lineHeight: "1.6em", marginTop: "1em" }}>
                      Yarn businesses
                      <br /> -Cotton Yarns <br />
                      -Polyester Yarns <br />
                      -Yarns for Technical textiles
                    </h5>
                  </Link>
                </li>
                <li>
                  <Link to="/our-sales">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>25+</h3>
                    <h5 style={{ lineHeight: "1.4em" }}>
                      Countries export <br />
                      Global sales network
                    </h5>
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/our-team">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>100+</h3>
                    <h5 style={{ lineHeight: "1.4em", marginTop: "0.6em" }}>
                      years of cumulative promoter experience in Yarn industry
                    </h5>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/esg">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>17%</h3>
                    <h5>ENERGY USED FROM RENEWABLE SOURCES</h5>
                  </Link>
                </li> */}
                <li>
                  <Link to="/about-us/our-team">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>3500+</h3>
                    <h5>Manpower employed</h5>
                  </Link>
                </li>
                <li>
                  <Link to="/products">
                    <h3 style={{ fontFamily: "Gordita-Bold" }}>12000+</h3>
                    <h5>Yarn Products</h5>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="home_sec2_fifth_row home_sec2_fifth_row_mobile">
              <div className="home_sec2_fifth_row_mobile_first_row">
                <div className="home_sec2_fifth_row_mobile_heading">
                  <div className="hollow_title home_sec2_fifth_row_mobile_heading_mobile">
                    One Yarn May Things
                  </div>
                </div>
                <div className="home_sec2_fifth_row_mobile_swiper_wrapper">
                  <div className="swiper-container home_sec2_fifth_row_mobile_swiper_container">
                    <ul className="swiper-wrapper">
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_1} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Medical</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Mask</p>
                              </li>
                              <li>
                                <p>- Bandages</p>
                              </li>
                              <li>
                                <p>- PPE Kits</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_2} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Sports {"&"} Athleisure</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Footwear</p>
                              </li>
                              <li>
                                <p>- Track suit</p>
                              </li>
                              <li>
                                <p>- Jeresey</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_3} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Apparel</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Shirting {"&"} Suiting</p>
                              </li>
                              <li>
                                <p>- Denim Fabrics</p>
                              </li>
                              <li>
                                <p>- Saree {"&"} Dress Material</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_4} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Active {"&"} Inner Wear</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Lingerie</p>
                              </li>
                              <li>
                                <p>- Swim wear</p>
                              </li>
                              <li>
                                <p>- Yoga {"&"} Gym</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_5} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Automotive</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Seat Belts</p>
                              </li>
                              <li>
                                <p>- Seat Cover</p>
                              </li>
                              <li>
                                <p>- Upholstery Fabrics</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_6} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Home Textiles</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Carpets {"&"} Bathmats</p>
                              </li>
                              <li>
                                <p>- Curtains {"&"} Cushions</p>
                              </li>
                              <li>
                                <p>- Bedsheets {"&"} Towels</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="swiper-slide">
                        <Link to="/ouryarn-in-your-life">
                          <figure>
                            <img src={our_yarn_7} alt="sanathan img" />
                          </figure>
                          <div className="home_sec2_fifth_row_mobile_slider_content">
                            <h5>Technical Textiles</h5>
                            <ul className="fadeIn-bottom">
                              <li>
                                <p>- Geogrid Fabrics</p>
                              </li>
                              <li>
                                <p>- Safety Sling</p>
                              </li>
                              <li>
                                <p>- Ropes {"&"} Nets</p>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="home_sec2_third_row">
              <ul className="home_sec2_third_row_img_container">
                <li>
                  <a href="/investor-relations">
                    <figure>
                      <img src={highlights_1} alt="sanathan img" />
                    </figure>
                    <figcaption>Investor Relations</figcaption>
                  </a>
                </li>
                <li>
                  <NavLink to="/newsroom">
                    <figure>
                      <img src={homepagenews} alt="sanathan img" />
                    </figure>
                    <figcaption>Newsroom</figcaption>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="home_sec2 home_sec2_mobile_2">
          <div className="home_sec2_fourth_row">
            <div className="wrapper">
              <div className="home_sec2_fourth_row_left">
                <h4>Tech in Textile</h4>
                <p>
                  With the next generation leading the way at Sanathan Textiles,
                  we are constantly focussed on how to imbibe the latest
                  technology for automation and data driven solutions into our
                  daily lives. As the world moves towards Industry 4.0, we
                  strive to be prepared for the future in every possible way.
                </p>
                <div className="bottom_arrow_container">
                  <Link to="/our-setup/tech-in-textile">Explore</Link>
                  <span className="arrow">
                    <img src={whiteArrow} alt="sanathan img" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper wrapper_2">
            <div className="home_sec2_fourth_row_right">
              <a>
                <img
                  src={video_image}
                  className="video_image"
                  alt="sanathan img"
                />
                <img
                  src={playicon}
                  className="playicon"
                  alt="sanathan img"
                  onClick={() =>
                    handleProjectVideoClick(
                      "https://www.youtube.com/embed/a6y1xzN1WD8"
                    )
                  }
                />
              </a>
              <div className="home_sec2_fourth_row_right_link">
                <div className="bottom_arrow_container">
                  <Link to="/our-setup/infrastructure">Visit our Factory</Link>
                  <span className="arrow">
                    <img src={coloredArrow} alt="sanathan img" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home_sec3" data-aos="fade-up">
          <div className="wrapper">
            <div className="hollow_title">ESG</div>
            <div className="home_sec3_content">
              <div className="home_sec3_left_content">
                <h3>
                  We are committed to the Environment, Social and Governance
                </h3>
                <p>
                  Sustainability is at the heart of every decision we make at
                  Sanathan Textiles. Caring for our people and our surroundings
                  is a natural instinct. It's our daily endeavor to make a
                  difference in every way possible.
                </p>
                <div className="bottom_arrow_container">
                  <Link to="/esg">Read More</Link>
                  <span className="arrow">
                    <img src={whiteArrow} alt="sanathan img" />
                  </span>
                </div>
              </div>
              <div className="home_sec3_right_content">
                <img src={esg} alt="sanathan img" />
              </div>
            </div>
          </div>
        </section>
        <section className="home_sec4" data-aos="fade-up">
          <section className="clients">
            <div className="clients_slider_wrapper swiper-container">
              <div className="clients_slider_wrapper_heading_section">
                <div className="left_section">
                  <div className="hollow_title">Our Customers</div>
                  <h3>We supply to 1150+ customers worldwide</h3>
                </div>
              </div>
              <ul className="swiper-wrapper">
                <li className="swiper-slide">
                  <img src={BrandImage1} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage2} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage3} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage4} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage5} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage6} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage7} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage8} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage9} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage10} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage11} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage12} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage13} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage14} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage15} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage16} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage17} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage18} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage19} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage20} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage21} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage22} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage23} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage24} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage25} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage26} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage27} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage28} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage29} alt="sanathan img" />
                </li>
                <li className="swiper-slide">
                  <img src={BrandImage30} alt="sanathan img" />
                </li>
              </ul>
            </div>
          </section>
        </section>

        <div className="info_container">
          <div className="wrapper">
            <div className="info_title_wrapper" onClick={toggleState}>
              <h3 className="info_title">More Information</h3>
              <i
                class={`fa fa-caret-down down_arrow ${isOpen ? "active" : ""}`}
              ></i>
            </div>
            <p className={`info_desc ${isOpen ? "show" : ""}`}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta,
              harum animi, similique impedit fuga magnam voluptate omnis iste
              amet quaerat atque. Iure blanditiis facere sint molestias ratione
              sed modi minus?
            </p>
          </div>
        </div>
      </main>
      <InfoModal
        show={shouldModalOpen}
        onHide={() => setShouldModalOpen(false)}
        ytvideolink={ytVideoLink}
      />
    </>
  );
};

export default Home;
