import { useEffect } from "react";
import "./Popfour.css";
import "./pop4.scss";
import { pop4 } from "../../images";

const ProductsPopup = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        console.log("close clicked");
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  useEffect(() => {
    window.scrollTo({
      top: 960,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="popup_form_wrapper4">
        <div className="products_popup4">
          <div className="img_and_close_button">
            <figure>
              <img src={pop4} alt="sanathan Air Textured Yarn image" />
            </figure>
            <button type="button" onClick={onRequestClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="wrapper">
            <h3>Air Textured Yarn (ATY)</h3>
            <p>
              ATY is an able substitute for the synthetic hand of false twist
              textured yarns and offers a fresh new look to the fabrics. ATY has
              a similar touch and warmth as that of cotton. The appearance and
              the physical characteristics of ATY resembles that of spun yarns.
              <br />
              <br />
              The process to produce ATY is completely mechanical and makes use
              of a cold-air stream to manufacture bulked yarns. ATY also finds
              application in a diverse range of fabrics, i.e. from a
              light-weight scrim to heavy-duty soft luggage. It is also used to
              make light-weight swimwear.
              <br /> <br />
              We are one of the few companies in India with presence across the
              polyester, cotton and technical textile sectors. (Source: CRISIL
              Report)
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsPopup;
