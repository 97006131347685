import React from "react";
import { Modal } from "react-bootstrap";
import { closeimg, playicon } from "../../images";
import "./VideoModal.scss";

const InfoModal = (props) => {
  return (
    <Modal {...props} dialogClassName="infoModel" centered>
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="model infoDetail">
        <img
          src={closeimg}
          alt="sanathan cross image"
          loading="lazy"
          className="modal_cross_brown"
          onClick={props.onHide}
        />
        <div className="videoContainer">
          <iframe
            className="iframes"
            id="areYouRiskReadyVideo"
            width="100%"
            height="100%"
            src={`${props.ytvideolink}?rel=0&amp;autoplay=1&mute=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="indoline project walkthrigh video"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
// https://www.youtube.com/embed/${props.youtubeId}
