import { useEffect } from "react";
import "./Popfive.css";
import "./pop5.scss";
import { pop5 } from "../../images";

const ProductsPopup = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        console.log("close clicked");
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  useEffect(() => {
    window.scrollTo({
      top: 960,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <section className="popup_form_wrapper5">
        <div className="products_popup5">
          <div className="img_and_close_button">
            <figure>
              <img src={pop5} alt="sanathan twisted yarn cover image" />
            </figure>
            <button type="button" onClick={onRequestClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="wrapper">
            <h3>Twisted Yarns (TW)</h3>
            <p>
              There are two main types of polyester yarns, including, filament
              yarns and spun yarns. Filament yarns are produced by using single
              polyester filaments and putting them together. The filament yarns
              are further twisted and air-entangled to augment its usage in
              various downstream processes like weaving, knitting, sizing etc.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsPopup;
