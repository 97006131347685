import { useEffect } from "react";
import "./ProductsPopup.css";
import "./pop1.scss";
import { pop1 } from "../../images";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";

const ProductsPopup = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        console.log("close clicked");
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  useEffect(() => {
    window.scrollTo({
      width: 50,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <MetaDecorator canonicalLink="https://www.sanathan.com/products/popup" />

      <section className="popup_form_wrapper">
        <div className="products_popup">
          <div className="img_and_close_button">
            <figure>
              <img src={pop1} alt="sanathan Partially oriented yarn" />
            </figure>
            <button type="button" onClick={onRequestClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="wrapper">
            <h3>Partially Oriented Yarn (POY)</h3>
            <p>
              We produce a wide range of{" "}
              <a href="https://iopscience.iop.org/article/10.1088/1742-6596/1295/1/012010#:~:text=Whereas%2C%20Partially%20Oriented%20Yarn%20(POY,very%20high%20percentage%20of%20elongation.">
                Partially Oriented Yarn{" "}
              </a>
              and We are one of the few companies in India with presence across
              the polyester, cotton and technical textile sectors. (Source:
              CRISIL Report). We have an inhouse testing facility having advance
              equipments such as wrap reel machine and weigh balance for denier
              testing (i.e. to determine the fibre thickness of individual
              threads or filaments), yarn strength tester to determine tensile
              properties (i.e., to check elongation and tenacity of yarns) and
              color viewing booth for visual colour evaluation, capable of
              catering to the international standards that our customers expect
              from us and our Product Innovation and Development team works
              constantly to analyse, test and improve our products. Our POY is
              made in Bright, Semi-Dull, Full-Dull and Cationic lustres.The
              products under this category are made by using dope dyed
              technology wherein the colour pigment is added to the yarn during
              the polymerisation stage. The fabric manufactured from this yarn
              is mainly utilized in the making of outer-wear, shirts, trousers,
              suiting, rainwear, ties, school belts, bags, upholstery etc.
            </p>

            <div className="product_items">
              {/* <h3>Product list</h3> */}
              <p>
                <strong>
                  The Advantages Of The Partially Oriented Yarns Manufactured By
                  Sanathan Textiles Include:
                </strong>
              </p>
              <ul>
                <li>
                  <p>Metered length and 100% Tail end</p>
                </li>
                <li>
                  <p>Superior & smooth unwinding at high speed</p>
                </li>
                <li>
                  <p>Uniformity of properties and oil application</p>
                </li>
                <li>
                  <p>High-Speed Productivity</p>
                </li>
              </ul>
            </div>
            <section className="pop_sec3">
              {/* <div className="row">
                <div className="col-lg-4 pop_box">
                  <div className="pop_head">Bright POY</div>
                  <div className="pop_desc">Denier range: 250 to 700</div>
                  <div className="pop_desc">Filament range: 72 to 288</div>
                </div>
                <div className="col-lg-4">
                  <div className="pop_head">Semi-Dull POY</div>
                  <div className="pop_desc">Denier range: 50 to 700</div>
                  <div className="pop_desc">Filament range: 14 to 288</div>
                </div>
                <div className="col-lg-4">
                  <div className="pop_head">Full-Dull POY</div>
                  <div className="pop_desc">Denier range: 100 to 250</div>
                  <div className="pop_desc">Filament range: 36 to 96</div>
                </div>
              </div> */}
              {/* <div className="products_inner_listing">
                <div className="pop_box">
                  <div className="pop_head">Bright POY</div>
                </div>
                <div className="pop_box">
                  <div className="pop_head">Semi-Dull POY</div>
                </div>
                <div className="pop_box">
                  <div className="pop_head">Full-Dull POY</div>
                </div>
                <div className="pop_box">
                  <div className="pop_head">Bright Cationic POY</div>
                </div>
                <div className="pop_box">
                  <div className="pop_head">Semi-Dull Cationic POY</div>
                </div>
                <div className="pop_box">
                  <div className="pop_head">
                    Dope Dyed POY in over 500 colours
                  </div>
                </div>
              </div> */}
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsPopup;
