import React, { useEffect } from "react";
import { Banner } from "../../components/Banner/Banner";
import Dash from "../../components/Dash/Dash";
import {
  blogimg,
  downdropdownarrow,
  blogimg1,
  blogimg2,
  blogimg3,
  blogimg4,
  blogimg5,
  blogimg6,
  blogimg7,
  NewsroomBannerImg,
  thumbnail,
  blog2,
  blog3,
  blogsBanner,
  blog4Thumbnail,
  blog5Thumbnail
} from "../../images";
import { Link, NavLink } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

import "./Blog.scss";

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blogListArr = [
    {
      id: 1,
      url: "/blog/recycled-pet-chip",
      img: thumbnail,
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      title: "Recycled Pet Chips 101: Explained",
      description:
        "Polyester is one of the world’s most popular textiles and it is used in thousands of different consumer and industrial applications. Worldwide, more than 22.67 billion tonnes of polyester clothes are produced each year.",
    },

    {
      id: 2,
      img: blog2,
      url: "blog/development-yarn-fibers",
      date: "August 12, 2022",
      category: "Lorem Ipsum",
      title: "What Are the Latest Yarn, And Fabric Developments?",
      description:
        "The fabrics and yarn industry is an essential component of the textile and apparel industries.",
    },
    {
      id: 3,
      img: blog3,
      url: "blog/technical-textiles",
      date: "August 12, 2022",
      category: "Lorem Ipsum",
      title: "What Are Some Examples Of Technical Textiles?",
      description:
        "Technical textiles in India are a vital component of modern life. These materials are employed in a wide range of industries, from medical gadgets to athletic products. This article will provide an overview of technical textiles, their examples and how it is used nowadays.",
    },
    {
      id: 4,
      img: blog4Thumbnail,
      url: "blog/re-weaving-indias-success-story",
      date: "September 22, 2022",
      category: "Lorem Ipsum",
      title: "Re-Weaving India’s Success Story",
      description:
        "India is globally known to set examples and break benchmarks as we proceed and lead from the front.",
    },
    {
      id: 5,
      img: blog5Thumbnail,
      url: "/blog/polyester-yarn-market-in-india",
      date: "September 22, 2022",
      category: "Lorem Ipsum",
      title: "Polyester Yarn Market Report And The Price Trend In India 2022",
      description:
        "Polyester is the most cost-effective and versatile synthetic fibre available in the industry. Given that the global growth of natural fibres is constrained by available resources, only polyester would see further capacity expansion.",
    }
  ];

  const blogList = blogListArr.length ? (
    blogListArr.reverse().map((blog, i) => (
      <div className="col-md-6 col-lg-4 blog_details_container" key={i}>
        <div className="blog_detail_wrapper">
          <Link to={blog.url} className="blog_card">
            <div className="imageanimate zoom-out">
              <img
                src={blog.img}
                alt="caterina our blog img"
                loading="lazy"
                className="blog_img"
              />
              {/* <div class="content-overlay"></div> */}
            </div>
            <div className="text_container">
              {/* <h1 className="blog_date">{blog.date} </h1>
              <div className="verticle_line"> | </div>
              <h1 className="blog_category">{blog.category}</h1> */}
              <h2 className="blog_name"> {blog.title}</h2>
              <p className="blog_synopsis">
                {reduceDescSize(blog.description, 100)}
              </p>
              <div className="blog_cta_wrapper">
                <button className="readmore_button">Read More</button>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ))
  ) : (
    <h1>No Blogs Found</h1>
  );
  return (
    <>
    <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Blogs | Sanathan Textiles"
        metaDesc="Explore the world of textiles with Sanathan Textile's Insights on quality fabrics, Yarns & Customised Yarns. Visit Us and Read More Now!"
      />
      <section className="newsroom">
        <div className="wrapper">
          <div class="breadcrumb_styles">Home {">"} Blogs</div>
          <div className="left_section">
            <h1 className="hollow_title">Blogs</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <p>
              Textile blogs have now become a trending and binge-worthy blog
              category. At Sanathan Textiles, we strive to provide you with
              relevant blogs from the industry along with hot fabric/textile
              news that you can feed on!
            </p>
          </div>
          <figure className="bg_img bg_img_desktop">
            <img src={blogsBanner} alt="sanathan img" />
          </figure>
        </div>
      </section>
      <figure className="bg_img bg_img_mobile wrapper">
        <img src={blogsBanner} alt="sanathan img" />
      </figure>
      <section className="blog_sec2">
        <div className="wrapper">
          {/* <div class="breadcrumb_styles">Home {">"} Blogs</div> */}
          <div className="row m-0">{blogList}</div>
          {/* <div className="horizontal_line"></div> */}
          <div className="loadmore_cta_wrapper center"></div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
