import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./common.css";
import Routing from "./Routing";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2400,
});

const App = () => {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //       layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  return (
    <>
      {/* <div id="google_translate_element"></div> */}

      <Router>
        <Switch>
          <Route path="/" component={Routing} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
